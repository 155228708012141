import { ReactNode } from 'react';
import { darkTheme, lightTheme } from '@lunarway/matter-web';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { GlobalStyles } from '_src/framework/styles/GlobalStyles';
import { useDetectSystemColorScheme } from '_src/framework/utils/use_detect_system_color_scheme';

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const { isDarkMode } = useDetectSystemColorScheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </StyledThemeProvider>
  );
};
