import React from 'react';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import { AppProps } from 'next/app';

import { ThemeProvider } from '_src/app/bootstrap/ThemeProvider';
import { RenderBlocker } from '_src/auth/RenderBlocker';
import { Query } from '_src/app/bootstrap/Query';
import { MockProvider } from '_src/app/bootstrap/MockProvider';
import '_src/framework/styles/fonts.css';
import '_src/framework/styles/reset.css';
import 'normalize.css';
import { VercelAnalytics } from '_src/app/vercel_analytics';
import { NotifyProvider } from '_src/app/notification';

const App = ({ Component, pageProps }: AppProps) => (
  <>
    <Head>
      <title>Lunar Loans</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="noindex, nofollow" />
    </Head>
    <RenderBlocker>
      <ThemeProvider>
        <MockProvider>
          <Query>
            <VercelAnalytics />
            <NotifyProvider>
              <Component {...pageProps} />
            </NotifyProvider>
          </Query>
        </MockProvider>
      </ThemeProvider>
    </RenderBlocker>
  </>
);

export default appWithTranslation(App);
