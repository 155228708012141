import { Analytics } from '@vercel/analytics/react';

const uuidRegex =
  /([a-f0-9]{8}(-[a-f0-9]{4}){3}-[a-f0-9]{12})|([a-f0-9]{32})|({[a-f0-9]{6}-[a-f0-9]{2}-[a-f0-9]{2}-[a-f0-9]{2}-[a-f0-9]{6}})/gi;

export const VercelAnalytics = () => (
  <Analytics
    beforeSend={(pageViewEvent) => {
      // eslint-disable-next-line no-param-reassign
      pageViewEvent.url = pageViewEvent.url.replace(uuidRegex, '<redacted>');

      return pageViewEvent;
    }}
  />
);
