import { useEffect, useState } from 'react';

export type SystemColorScheme = 'dark' | 'light';

export function useDetectSystemColorScheme() {
  const [systemColorScheme, setSystemColorScheme] =
    useState<SystemColorScheme>('light');
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  useEffect(() => {
    if (systemColorScheme === 'dark') {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
  }, [systemColorScheme]);

  useEffect(() => {
    try {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setSystemColorScheme('dark');
      } else {
        setSystemColorScheme('light');
      }

      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', toggleSystemColorScheme);
    } catch {
      setSystemColorScheme('light');
    }

    return () => {
      try {
        window
          .matchMedia('(prefers-color-scheme: dark)')
          .removeEventListener('change', toggleSystemColorScheme);
      } catch {
        // TODO: LOG ERROR
      }
    };
  }, []);

  function toggleSystemColorScheme(event: MediaQueryListEvent) {
    if (event.matches) {
      setSystemColorScheme('dark');
    } else {
      setSystemColorScheme('light');
    }
  }

  return {
    isDarkMode,
    systemColorScheme,
  };
}
