import { ReactNode, useEffect, useState } from 'react';

type MockProviderProps = {
  children: ReactNode;
};

export const MockProvider = ({ children }: MockProviderProps) => {
  const [importing, setImporting] = useState(
    process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'
  );

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
      // eslint-disable-next-line global-require
      loadMock().then(() => {
        setImporting(false);
      });
    }
  }, []);

  return <> {importing ? null : children} </>;
};

async function loadMock() {
  const { init } = await import('mocks');
  await init();
}
