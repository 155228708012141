import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Toast,
  ToastProps,
  ToastProvider,
  ToastViewport,
} from '@lunarway/matter-web';

type Success = Pick<ToastProps, 'title' | 'actionLabel' | 'onAction'>;
type Info = Pick<
  ToastProps,
  'title' | 'actionLabel' | 'onAction' | 'adornmentStart'
>;

type Warn = Pick<ToastProps, 'title' | 'actionLabel' | 'onAction'>;

interface StateProps {
  info: (options: Info) => void;
  success: (options: Success) => void;
  warn: (options: Warn) => void;
}
export const DefaultNotifyContext = createContext<StateProps>({
  info: () => {},
  success: () => {},
  warn: () => {},
});

export function useNotify() {
  return useContext(DefaultNotifyContext);
}

type Notification = Pick<
  ToastProps,
  'title' | 'actionLabel' | 'onAction' | 'variant' | 'adornmentStart'
>;

interface NotifyProviderProps {
  children: ReactNode;
}
export const NotifyProvider = ({ children }: NotifyProviderProps) => {
  const [open, setOpen] = useState(false);
  const timerRef = useRef(0);
  const [notification, setNotification] = useState<undefined | Notification>(
    undefined
  );

  useEffect(() => () => clearTimeout(timerRef.current), []);

  const api = useMemo(
    () => ({
      info(options: Info) {
        addNotification(options);
      },
      success(options: Success) {
        addNotification({ ...options, variant: 'success' });
      },
      warn(options: Warn) {
        addNotification({
          variant: 'error',
          title: options.title,
          actionLabel: options.actionLabel,
          onAction: options.onAction,
        });
      },
    }),
    []
  );

  const addNotification = (options: Notification) => {
    setOpen(false);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      setNotification(options);
      setOpen(true);
    }, 250);
  };

  return (
    <DefaultNotifyContext.Provider value={api}>
      <ToastProvider>
        <Toast
          key={notification?.title}
          actionLabel={notification?.actionLabel}
          adornmentStart={notification?.adornmentStart}
          onAction={notification?.onAction}
          onOpenChange={setOpen}
          open={open}
          title={notification?.title ?? ''}
          variant={notification?.variant}
        />
        <ToastViewport />
        {children}
      </ToastProvider>
    </DefaultNotifyContext.Provider>
  );
};
