import { useEffect } from 'react';

import { useNotify } from './notify_context';

export const useWarnOnError = (
  title: string,
  actionLabel?: string,
  errorThrown?: unknown,
  onAction?: () => void
) => {
  const { warn } = useNotify();

  useEffect(() => {
    if (errorThrown) {
      warn({
        title,
        actionLabel,
        onAction,
      });
    }
  }, [title, errorThrown, actionLabel, warn, onAction]);
};
