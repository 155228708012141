import { ReactNode, useEffect, useState } from 'react';

export type RenderBlockerProps = {
  children: ReactNode;
};

export const RenderBlocker = ({ children }: RenderBlockerProps) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  if (!isReady) {
    return null;
  }

  return <div>{children}</div>;
};
